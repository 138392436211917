<template>
  <div class="log-item">
    <div class="info d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <img
          class="avatar"
          :src="propsData.image_url"
          @click="redirectProfile()"
        />
        <div class="text-left">
          <div class="status d-flex align-items-center">
            <span
              class="dot"
              :class="!propsData.is_public ? 'in_active' : ''"
            ></span>
            <span
              class="status f-w3 mb-0"
              :class="!propsData.is_public ? 'in_active' : ''"
            >
              {{ propsData.is_public ? "表示中" : "非表示中" }}
            </span>
          </div>
          <div class="flex-nickname">
            <span class="nickname mb-0 f-w6">{{ propsData.nickname }}</span>
            <span class="age mb-0 f-w3">{{ propsData.age }}歳</span>
          </div>
          <p class="course-name f-w3 mb-0">{{ propsData.golf_course }}</p>
        </div>
      </div>
      <div class="text-right">
        <div class="date f-w3">{{ propsData.date_time }}</div>
        <label class="switch" :class="$route.query.user_id ? 'd-none' : ''">
          <input
            type="checkbox"
            v-model="propsData.is_public"
            @click="publicRoundlog()"
          />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="evaluation">
      <div
        class="point d-flex align-items-center position-relative"
        :class="$route.query.sex == 2 ? '' : 'point-men'"
      >
        <div class="point__div div-score position-absolute">
          <label class="point__label f-w3">スコア</label>
          <p class="point__p mb-0">{{ propsData.score }}</p>
        </div>
        <div class="point__div div-evaluation position-absolute">
          <label class="point__label f-w3">満足度</label>
          <p class="point__p mb-0">{{ propsData.evaluation }}</p>
        </div>
        <div
          class="point__div div-car position-absolute"
          v-if="$route.query.sex == 1"
        >
          <label class="point__label f-w3">運転の評価</label>
          <p class="point__p mb-0">{{ propsData.car_driving }}</p>
        </div>
      </div>
      <div class="comment" v-if="isExpand">
        <p class="comment__p f-w3 text-left">
          {{ propsData.comment }}
        </p>
        <hr class="comment__hr" />
      </div>
      <button
        v-if="!isExpand"
        @click="isExpand = !isExpand"
        class="btn-expand f-w3"
      >
        <span class="btn-expand__span">コメント</span>
        <img src="@/assets/image/roundlog/arrow-down.svg" />
      </button>
      <button v-else @click="isExpand = !isExpand" class="btn-expand">
        <img src="@/assets/image/roundlog/arrow-up.svg" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoundLogItem",
  data() {
    return {
      isExpand: false,
      set: 1,
      active: 0,
      user: null,
      image_url: null
    };
  },
  props: {
    propsData: {
      type: Object,
      require: true
    },
    propsId: {
      type: Number
    },
    propsSex: {
      type: Number,
      default: 1
    }
  },
  // async created() {
  //   await this.getProfile();
  // },
  methods: {
    // async getProfile() {
    //   await this.$store.dispatch("userApp/getInfoUser", this.propsData.user_id);
    //   this.user = this.$store.getters["userApp/user"].user;
    //   for (let i = 0; i < this.user.image_urls.length; i++) {
    //     if (this.user.image_urls[i].type == 1) {
    //       if (this.user.image_urls[i].display_order == 1) {
    //         this.image_url = this.user.image_urls[i].image_url;
    //       }
    //     }
    //   }
    // },
    redirectProfile() {
      if (this.propsSex == 1) {
        if (this.propsData.user_id == this.propsId) {
          this.$router.push({
            name: "ProfileViewMyProfile"
          });
        } else {
          this.$router.push({
            name: "CastProfile",
            params: { id: this.propsData.user_id }
          });
        }
      } else {
        if (this.propsData.user_id == this.propsId) {
          this.$router.push({
            name: "CastMyPageProfile"
          });
        } else {
          this.$router.push({
            name: "MenProfile",
            params: { id: this.propsData.user_id }
          });
        }
      }
    },
    publicRoundlog() {
      let data = {
        roundlog_id: this.propsData.id,
        is_public: this.propsData.is_public
      };
      this.$emit("publicRoundlog", data);
    }
  }
};
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
}
button {
  outline: none;
  box-shadow: none;
  width: 100%;
}
.log-item {
  padding: 15px 0 15px 0;
  box-shadow: #00000029 0 3px 6px;
  border-radius: 5px;
  width: 100%;
  .info {
    padding: 0 18px;
    width: 100%;
    margin-bottom: 10px;
    .avatar {
      width: 47px;
      min-width: 47px;
      height: 47px;
      cursor: pointer;
      border-radius: 50%;
      margin-right: 11px;
    }
    .dot {
      display: inline-block;
      height: 11px;
      width: 11px;
      border-radius: 50%;
      background-color: #059854;
      margin-right: 10px;
      &.in_active {
        background-color: #9c9c9c;
      }
    }
    .status {
      font-size: 14px;
      color: #059854;
      &.in_active {
        color: #9c9c9c;
      }
    }
    .nickname {
      font-size: 14px;
      line-height: 21px;
      margin-right: 20px;
      color: #000000;
    }
    .age {
      font-size: 12px;
      line-height: 21px;
    }
    .course-name {
      font-size: 12px;
      color: #818181;
    }
    .date {
      font-size: 10px;
      margin-bottom: 10px;
      color: #6e6e6e;
    }
  }
  .evaluation {
    width: 301px;
    margin: auto;
    .point {
      background: url("~@/assets/image/roundlog/roundlog-background.svg");
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 10px;
      color: #464d77;
      height: 97px;
      &.point-men {
        background: url("~@/assets/image/roundlog/roundlog-background-men.svg");
        background-repeat: no-repeat;
        background-size: cover;
        .point__div {
          width: calc(100% / 3);
          &.div-score {
            top: 20px;
            left: 15px;
          }
          &.div-evaluation {
            top: 20px;
            left: 100px;
            right: 0;
          }
          &.div-car {
            top: 20px;
            right: 15px;
          }
        }
      }
      &__div {
        width: calc(100% / 3);
        &.div-score {
          top: 20px;
          left: 57px;
        }
        &.div-evaluation {
          top: 20px;
          right: 57px;
        }
      }
      &__label {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 0;
      }
      &__p {
        font-size: 26px;
        line-height: 30px;
        font-family: "ITC Bookman, Light Italic";
      }
    }
    .comment {
      &__hr {
        margin: 15px 0;
        background-color: #d9d9d9;
      }
      &__p {
        word-break: break-word;
        font-size: 14px;
        color: #000000;
      }
    }
    .btn-expand {
      background: none;
      border: none;
      color: #818181;
      font-size: 14px;
      outline: none;
      box-shadow: none;
      &__span {
        margin-right: 10px;
      }
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 25px;
  margin-bottom: 0;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 2px;
  bottom: 2px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #1bb86f;
}
input:focus + .slider {
  box-shadow: 0 0 1px #1bb86f;
}
input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

@media screen and (max-width: 374px) {
  .log-item {
    .evaluation {
      .point {
        padding: 10px 17px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .flex-nickname {
    align-items: center;
  }
  .log-item {
    padding: 18px 0 15px 0;
    .info {
      padding: 0 22px;
      .avatar {
        width: 56px;
        min-width: 56px;
        height: 56px;
        margin-right: 14px;
      }
      .dot {
        height: 13px;
        width: 13px;
      }
      .status {
        font-size: 16px;
      }
      .nickname {
        font-size: 16px;
        line-height: 24px;
        margin-right: 20px;
        color: #000000;
      }
      .age {
        font-size: 14px;
        line-height: 24px;
      }
      .course-name {
        font-size: 14px;
      }
      .date {
        font-size: 12px;
        margin-bottom: 12px;
      }
    }
    .evaluation {
      width: 360px;
      .point {
        height: 116px;
        &__div {
          width: calc(100% / 3);
          &.div-score {
            top: 30px;
            left: 68px;
          }
          &.div-evaluation {
            top: 30px;
            right: 68px;
          }
        }
        &.point-men {
          .point__div {
            &.div-score {
              top: 30px;
              left: 20px;
            }
            &.div-evaluation {
              top: 30px;
              left: 120px;
              right: 0;
            }
            &.div-car {
              top: 30px;
              right: 20px;
            }
          }
        }
        &__label {
          margin-bottom: 0px;
        }
      }
      .btn-expand {
        font-size: 16px;
        &__span {
          margin-right: 17px;
        }
      }
      .comment {
        &__p {
          font-size: 16px;
        }
      }
    }
  }
  .switch {
    width: 56px;
    height: 29px;
  }
  .slider:before {
    height: 25px;
    width: 25px;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
  }
}
</style>
