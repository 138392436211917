<template>
  <div class="round-log-list">
    <AppContainer :useBackground="true">
      <template v-if="isLoading">
        <AppLoading />
      </template>
      <template v-else>
        <card-header ref="header" :node="headerTitle" />
        <div id="content-scroll" class="content-scroll">
          <div
            class="log-list d-flex flex-wrap"
            v-if="listRoundlogs.length > 0"
          >
            <div
              class="log-list__div"
              v-for="(roundlog, index) in listRoundlogs"
              :key="index"
            >
              <RoundLogItem
                :propsData="roundlog"
                :propsId="authUser.user_id"
                :propsSex="authUser.sex"
                @publicRoundlog="publicRoundlog($event)"
              />
            </div>
          </div>
          <div v-else class="empty-data">
            <img
              src="~@/assets/image/icon_profile/icon-empty-data.svg"
              alt=""
            />
            <div>現在データはありません。</div>
          </div>
          <scroll-loader :loader-method="getList" :loader-disable="disable">
            <AppLoading v-if="page !== 1" />
            <p v-else></p>
          </scroll-loader>
        </div>
        <card-footer ref="footer" prefer="mypage" />
      </template>
    </AppContainer>
  </div>
</template>

<script>
import CardFooter from "@/components/CardFooter.vue";
import CardHeader from "@/components/CardHeader.vue";
import RoundLogItem from "@/views/RoundLogItem.vue";
import { mapGetters } from "vuex";
import service from "@/utils/axios";
import { ROUNDLOG_LIST } from "@/api/api";

export default {
  name: "RoundLogList",
  components: {
    "card-footer": CardFooter,
    "card-header": CardHeader,
    RoundLogItem
  },
  computed: {
    ...mapGetters({
      isLoading: "common/isLoading",
      authUser: "auth/user"
    })
  },
  metaInfo() {
    return {
      title: "ラウンドログ",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      headerTitle: {
        text: "ラウンドログ",
        isShowTitle: true,
        isShowLogo: false
      },
      isShowNotification: false,
      listRoundlogs: [],
      page: 1,
      perpage: 8,
      query: {}
    };
  },
  async created() {
    this.$store.dispatch("common/setIsLoading", true);
    this.query = {
      page: this.page,
      perpage: this.perpage
    };
    if (this.$route.query.user_id) {
      this.query.user_id = this.$route.query.user_id;
    }
    await this.requestData(this.query);
    this.$store.dispatch("common/setIsLoading", false);
  },
  methods: {
    async requestData(query) {
      await this.$store
        .dispatch("roundlog/rounglogList", query)
        .then(response => {
          if (this.page === 1) {
            this.listRoundlogs = response.data.round_logs;
          } else {
            this.listRoundlogs = [
              ...this.listRoundlogs,
              ...response.data.round_logs
            ];
          }
          let total = response.data.total;
          if (total < this.page * this.perpage) {
            this.disable = true;
          } else {
            this.disable = false;
          }
          this.page++;
          if (this.page > 1) {
            this.query.page = this.page;
          }
        });
    },
    publicRoundlog(data) {
      // let is_public = data.is_public ? 0 : 1;
      service({
        url: ROUNDLOG_LIST + "/" + data.roundlog_id,
        method: "PUT",
        data: {
          is_public: data.is_public ? 0 : 1
        }
      }).then(response => {
        const item = this.listRoundlogs.find(item => item.id === data.id);
        if (response.data.success === true) {
          item.is_public = data.is_public;
        }
      });
    },
    async getList() {
      if (this.page === 1) return false;
      await this.requestData(this.query);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_fontFamily.scss";

.log-list {
  padding: 18px 19px;
  &__div {
    width: 100%;
    margin-bottom: 25px;
  }
}
.content-scroll {
  // overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 40px;
  padding-bottom: 60px;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  .content-scroll {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .log-list {
    padding: 46px 121px;
    &__div:nth-child(2n + 1) {
      margin-right: 25px;
    }
    &__div {
      width: calc((100% - 25px) / 2);
    }
  }
}
</style>
